import axios from 'axios'
axios.defaults.withCredentials = true
//import https from 'https'
/*
2do:
- handle all response scenarios (in this and all other axios calls)
- consider moving all axios calls to separate services
- secure against CSRF attacks: https://flask-jwt-extended.readthedocs.io/en/stable/tokens_in_cookies/
- ensure we're secure against XSS & other attacks: https://flask.palletsprojects.com/en/1.1.x/security/
  - http://www.redotheweb.com/2015/11/09/api-security.html
- find out if there's a way to log out without having internet access

  
done: - fix logout to delete/clear authorization cookie
*/

export default({
  state: {
    user: null,
    login_dialog: false,
  },
  mutations: {
    SET_USER_DATA (state, userData) {
      state.user = userData
      localStorage.setItem('user', JSON.stringify(userData))
      axios.defaults.headers.common['Authorization'] = `Bearer ${
        userData.token
      }`
    },
    CLEAR_USER_DATA (state) {
      localStorage.removeItem('user')
      state.user = null
    },
    LOGIN_DIALOG (state, newval) {
      state.login_dialog = newval
    }
  },
  actions: {
    login ({ commit }, credentials) {
      return axios
        .post(process.env.VUE_APP_API_URL + '/login', credentials)
        .then(({ data }) => {
          commit('SET_USER_DATA', data)
          commit('LOGIN_DIALOG', false)
        })
        .catch(err =>{
          console.log('login error:',err.response, err)
        })
    },
    login_dialog ({ commit }, newval) {
      commit('LOGIN_DIALOG', newval)
    },
    logout({ commit }) {
      commit('CLEAR_USER_DATA')
      return axios
        .post(process.env.VUE_APP_API_URL + '/logout')
        .then(({ data }) => {
          if (! data) {
            console.log('empty response')
          }
          location.reload()
        })
        .catch(err =>{
          location.reload()
          console.log('logout error:',err.response, err)
        })
    },
    browserRefresh({ commit }) {
      const userString = localStorage.getItem('user')
      if (userString) {
        const userData = JSON.parse(userString)
        commit('SET_USER_DATA', userData)
      }
    }
  }
});