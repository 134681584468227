var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"mb-16"},[_c('v-card-title',[_vm._v(" Client List "),_c('v-spacer'),_c('v-spacer'),_c('v-spacer'),_c('v-spacer'),_c('v-spacer'),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-card-subtitle',[_c('FilterSelector',{on:{"change":function($event){_vm.active_filters = $event}}}),_vm._v(" "+_vm._s(_vm.filter_title)+" ")],1),_c('v-data-table',{ref:"dt",staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.filtered_clients,"search":_vm.search,"multi-sort":"","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"hide-default-footer":"","items-per-page":1000},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.business_address",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.addressify(item.physical_address)))])]}},{key:"item.condensed_address",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.addressify(Object.assign({}, item.physical_address, {condensed: true}))))])]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"warning","icon":""},on:{"click":function($event){_vm.prepped_client = item.operating_name; _vm.delete_dialog=true}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1)]}},{key:"header.edit",fn:function(){return [_c('ColumnSelector',{attrs:{"columns":_vm.headers,"hack":_vm.header_hack},on:{"change":function($event){_vm.visible_columns = $event}}})]},proxy:true},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","to":{name: 'Client Details', params: {client_id: _vm.URLify(item.operating_name)} },"exact":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1)]}}])})],1),_c('SimpleDialog',{attrs:{"model":_vm.delete_dialog,"title":"Erase Client?","body":'This will delete all client information for ' + _vm.prepped_client +'.  Are You Sure?',"yes":"Keep","no":"Delete Client"},on:{"response":function($event){return _vm.delete_client(!$event)},"dialog":function($event){_vm.delete_dialog = $event}}}),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"white--text",staticStyle:{"position":"fixed","bottom":"0","right":"0","margin":"16px 16px 16px 16px"},attrs:{"color":"pink","fab":"","to":{name: 'Client Details' }}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v("add new client")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }