<template>
  <div @click="check">
    <!--v-row v-if="state===3">
      <v-icon color="pink">mdi-alert-box</v-icon>
      <span>{{label}}</span><v-spacer></v-spacer>
      <v-text-field
        v-model="date_local"
        style="width: 100px"
        :label="label_local"
        @click="uncheck"
        @change="date_change"
      ></v-text-field>


      :disabled="disabled"

    </v-row-->
    <v-checkbox
      v-model="checked_local"
      :indeterminate="disabled" :class="{ 'disabled': disabled }"
      :disabled="disabled"
    >
      <template v-slot:label>
          <span>{{label}}</span><v-spacer></v-spacer>
          <v-text-field
            v-if="dated_local"
            v-model="date_local"
            style="width: 100px"
            :label="label_local"
            @click="uncheck"
            @change="date_change"
          ></v-text-field>
      </template>
    </v-checkbox>
  </div>
</template>

<script>
/*
2do for next iteration:
- refactor layout to not rely on / use checkbox component (rebuild it from scratch):
  - tackle the layout issue with the pink mdi-alert-box
  - make the label & date fields of fixed width
- datepicker:
  - consider adding " (dated?)" to label which turns into a datepicker & text field on click
  - consider turning the text field into a combobox
- if a date is left in & then box is unticked it should cross out
- find out if there's a way to make some props type-strict and some not
*/
export default {
  props: [
    'label',
    'vmodel',
    'states',
    'dated'
  ],
  model: {
    prop: 'vmodel'
  },
  data: () =>({
    checked_local: false,
    state: 0,
    disabled: false,
    date_local: '',
    states_local: 3,
    dated_local: true,
    vmodel_unchanged: null,
  }),
  computed: {
    label_local() {
      if (this.state === 2) { return 'N/A' }
      if (this.state === 3) { return 'TBD' }
      return 'Dated'
    }
  },
  watch: {
    vmodel() {
      this.vmodel_unchanged = this.vmodel
      this.set_state_and_date()
    },
    dated() { this.dated_local = this.dated === false ? false : true }
  },
  methods: {
    date_change() {
      this.state = this.get_state(this.date_local)
      this.update_checkbox()
    },
    check() {
      this.state = this.increment_state(1)
      this.update_checkbox()
    },
    uncheck() {
      this.state = this.increment_state(-1)
      this.update_checkbox()
    },
    increment_state(value){
      value = value === -1 ? this.states_local - 1 : 1
      return (this.state + value) % this.states_local
    },
    get_state(text) {
      if (text === 'TBD') { return 3 }
      else if (text === 'N/A' || text === 'NA') { return 2 } 
      else if (text) { return 1 }
      return 0
    },
    update_checkbox() {
      var new_vmodel = null
      if (this.state === 0) {
        this.disabled = false
        this.checked_local = false
        new_vmodel = false
      }
      else if (this.state === 1) {
        this.checked_local = true
        new_vmodel = this.date_local || true
      }
      else if (this.state === 2) {
        this.checked_local = null
        this.disabled = true
        new_vmodel = 'N/A'
      }
      else if (this.state === 3) {
        this.checked_local = null
        this.disabled = true
        new_vmodel = 'TBD'
      }
      //console.log(this.vmodel_unchanged, '=?=', this.vmodel)
      if (this.vmodel_unchanged === new_vmodel) { return null }
      this.$emit('input', new_vmodel)
      this.$emit('change', this.vmodel)
      this.vmodel_unchanged = this.vmodel
    },
    set_state_and_date() {
      if (typeof this.vmodel === 'string' && !['NA', 'N/A', 'TBD'].includes(this.vmodel)) { this.date_local = this.vmodel }
      this.state = this.get_state(this.vmodel)
      this.update_checkbox()
    }
  },
  created() {
    this.vmodel_unchanged = this.vmodel
    if (this.dated === false) { this.dated_local = false}
    if (this.states) { this.states_local = this.states }
    this.set_state_and_date()
  }
}
</script>

<style scoped>
  .disabled {
    text-decoration: line-through
  }
</style>