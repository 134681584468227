<template>
  <v-card>
    <v-card-text>
      <v-subheader>Primary Contact</v-subheader>

      <ContactDetails :values="values.primary_contact || {}" order="Primary" @change="primary_contact = $event; change()" />

      <v-divider class="mt-4"></v-divider>
      <v-subheader>Alternate Contact</v-subheader>

      <ContactDetails :values="values.secondary_contact || {}" order="Alternate" @change="secondary_contact = $event; change()" />

      <v-divider class="mt-4"></v-divider>

      <v-textarea
        v-model="how"
        label="How did you hear about us?"
        rows="2"
        @change="change"
      ></v-textarea>

    </v-card-text>
  </v-card>
</template>

<script>
let form_variables = {
  primary_contact: {},
  secondary_contact: {},
  how: '',
}
import ContactDetails from './contactDetails.vue'
export default {
  components: {
    ContactDetails,
  },
  props: {values: Object},
  data: () =>({
    ...form_variables,
  }),
  computed: {

  }, 
  watch: {
    values() {
      this.update_form_variables()
    }
  },
  methods: {
    change() {
      let payload = {}
      for (const form_var in form_variables) {
        payload[form_var] = this[form_var]
      }
      this.$emit('change', payload)
    },
    update_form_variables() {
      const values = this.values
      for (const item in form_variables) {
        if (this[item] && values[item] && (this[item] != values[item]) && (typeof form_variables[item] != 'object') ) {
          console.log('nameAddress.vue:', item, 'conflict!', this[item], 'to be overwritten by', values[item])
        } else if (values[item]) {
          this[item] = values[item]
        }
      }
      this.change()
    }
  },
  created() {
    this.update_form_variables()
  }
}
</script>