<template>
  <v-dialog
    v-model="dialog"
    max-width="600"
  >
    <v-card>
      <v-card-title class="headline grey lighten-2">
        {{title}}
      </v-card-title>

      <v-card-text>
        {{body}}
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          v-if="yes"
          color="primary"
          @click="response(true)"
        >
          {{yes}}
        </v-btn>

        <v-btn
          v-if="no"
          color="primary darken-1"
          text
          @click="response(false)"
        >
          {{no}}
        </v-btn>

        <v-btn
          @click="dialog = false"
        >
          cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/*
usage instructions:
- 'dialog_name' is the variable name in the vuex state
- to open & close the dialog dispatch this.$store.dispatch('set_var', {variable: 'dialog_name', value: newval})
  - where newval (boolean) is whether or not to open or close the dialog
  - & 'dialog_name' is the same provided in a prop

2do:
- make it prettier ()
- pass an optional icon prop so it can display a big centered icon (like Wave does)
  - animate that icon
- allow passing an array of text instead of just 1 text item

consideration:
- could use 1 prop as an open/close listener which needs to be set false then set true to open
  - or set true then set false to close
  - this has the benifit of not needing vuex
  - but this may be an anti-pattern since those boolean props wont tell you the state of the dialog
  - I need to answer the question of who gets control of this dialog:
    - I like it to be able to opened from it's parent but then closed from within
  - I think vuex should have MOST variables behave the way they do now (not changed by children)
  - but there should be a special class of variable that can be changed down the line
    - maybe this is what vuex does?
*/

export default {
  props: {
    model: Boolean,
    title: String,
    body: String,
    no: String,
    yes: String
  },
  data: () => ({
    dialog: false,
  }),
  watch: {
    model(model) {this.dialog = model},
    dialog(dialog) {this.$emit('dialog', dialog)}
  },
  methods: {
    response(value) {
      this.$emit('response', value)
    }
  }
}
</script>