<template>
  <div>
    <v-subheader>{{title}}</v-subheader>

    <v-row dense>
      <v-col cols="12" sm="6" v-bind:class="{ 'my-n2': $vuetify.breakpoint.smAndDown }">
        <v-text-field
          v-model="name"
          :label="title + ' Name'"
          :prepend-icon="icon"
          @change="change"
        ></v-text-field>
      </v-col><v-col cols="12" sm="6" v-bind:class="{ 'my-n2': $vuetify.breakpoint.smAndDown }">
        <v-text-field
          v-model="firm_name"
          :label="firm ? firm : 'Firm'"
          :prepend-icon="firm_icon ? firm_icon : 'mdi-office-building'"
          @change="change"
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="4" class="my-n2">
        <v-text-field
          v-model="phone"
          label="Business Phone"
          prepend-icon="mdi-phone"
          @change="change"
        ></v-text-field>
      </v-col><v-col cols="12" sm="4" class="my-n2">
        <v-text-field
          v-model="fax"
          label="Business Fax"
          prepend-icon="mdi-fax"
          @change="change"
        ></v-text-field>
      </v-col><v-col cols="12" sm="4" class="my-n2">
        <v-text-field
          v-model="email"
          label="Email"
          prepend-icon="mdi-email"
          @change="change"
        ></v-text-field>
      </v-col>
    </v-row>

    <AddressBlock :values="values.physical_address || {}" @change="physical_address = $event; change()" />
  </div>
</template>

<script>
let form_variables = {
  name: '',
  firm_name: '',
  phone: '',
  fax: '',
  email: '',
  physical_address: {},
}
import AddressBlock from './addressBlock.vue'
export default {
  components: { AddressBlock },
  props: {
    title: String,
    firm: String,
    icon: String,
    firm_icon: String,
    values: Object
  },
  data: () => ({
    ...form_variables,
  }),
  watch: {
    values() {
      this.update_form_variables()
    }
  },
  methods: {
    change() {
      let payload = {}
      for (const form_var in form_variables) {
        payload[form_var] = this[form_var]
      }
      this.$emit('change', payload)
    },
    update_form_variables() {
      const values = this.values
      for (const item in form_variables) {
        if (this[item] && values[item] && (this[item] != values[item]) && (typeof form_variables[item] != 'object') ) {
          console.log('nameAddress.vue:', item, 'conflict!', this[item], 'to be overwritten by', values[item])
        } else if (values[item]) {
          this[item] = values[item]
        }
      }
      this.change()
    }
  },
  created() {
    this.update_form_variables()
  }
}
</script>