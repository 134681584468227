<template>
  <v-container fluid>
    <v-form ref="form">
      <v-card-title class="pl-14 mt-n2">{{title}}</v-card-title>
      <v-row>
        <v-col cols="12" md="6">
          <NameAddress :values="customer_data" @change="name_address = $event;track_changes()" />
        </v-col><v-col cols="12" md="6">
          <Contacts :values="customer_data" @change="contacts = $event;track_changes()" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <Details :values="customer_data" @change="details = $event;track_changes()" />
        </v-col><v-col cols="12" md="6">
          <ServiceProviders :values="customer_data" @change="service_providers = $event;track_changes()" />
        </v-col>
      </v-row>

      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            icon
            :to="{name: 'Client List' }"
            style="position:fixed;top:0;left:0;margin: 16px 16px 16px 16px;"
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
        </template>
        <span>Back to client list</span>
      </v-tooltip>

      <!--v-btn
        color="pink"
        icon
        outlined
        style="position:fixed;top:0;right:0;margin: 16px 16px 16px 16px;"
      >
        <v-icon>mdi-account</v-icon>
      </v-btn-->
      <v-btn
        :color="saved ? 'success' : 'primary'"
        style="position:fixed;bottom:0;right:0;margin: 16px 16px 16px 16px;"
        @click="save"
        :loading="saving"
      >
        <v-icon v-if="saved" class="mr-2">mdi-check</v-icon>
        {{saved ? 'saved' : 'save' }}
      </v-btn>
    </v-form>
    <SimpleDialog
      :model="navigate_dialog"
      title="Save Changes?"
      body="This page has unsaved changes which will be lost if you don't save"
      yes="Save"
      no="Don't Save"
      @response="save_changes"
      @dialog="navigate_dialog = $event"
    />
  </v-container>
</template>

<script>

function URLify(url) {
  return url ? encodeURIComponent(url.replace(/[\s_]/g, function($1) { return $1 === '_' ? ' ' : '_' })) : ''
}
function deURLify(url) {
  return url ? decodeURIComponent(url.replace(/[\s_]/g, function($1) { return $1 === '_' ? ' ' : '_' })) : ''
}
let form_data = {
  name_address: {},
  contacts: {},
  details: {},
  service_providers: {}
}
const majhack_number = 13

import axios from 'axios'
axios.defaults.withCredentials = true
import { mapState } from 'vuex';

import NameAddress from './nameAddress.vue'
import Contacts from './contacts.vue'
import Details from './details.vue'
import ServiceProviders from './serviceProviders.vue'
import SimpleDialog from './simpledialog.vue'
export default {
  name: 'ClientDetails',
  props: ['client_id'],
  components: {
    NameAddress,
    Contacts,
    Details,
    ServiceProviders,
    SimpleDialog
  },
  data: () => ({
    customer_data: {},
    ...form_data,
    starting_values: '',
    changes_hack: 0,
    saved: true,
    unsaved_changes: false,
    navigate_dialog: false,
    saving: false,
  }),
  computed: {
    ...mapState({ user: state => state.login.user }),
    title() {
      const new_name = this.name_address.operating_name
      if (!this.client_id) {
        return "Add New Client" + (new_name ? ': ' + new_name + '' : '')
      }
      const old_name = this.customer_data.operating_name
      if ( (old_name != new_name) && old_name && new_name ) {
        return old_name + ' (to be renamed ' + new_name + ')'
      }
      return old_name || new_name
    },
    operating_name() {
      return deURLify(this.client_id)
    },
  },
  watch: {
    user(user) {
      if (user) { 
        this.$store.dispatch('login_dialog', false)
        location.reload()
        //this.$store.dispatch('fetch_clients')
        //  .then(() => {
        //    this.$router.push({name: 'Client Details', params: {client_id: URLify(this.operating_name)} })
        //  })
      }
    }
  },
  methods: {
    track_changes() {
      this.changes_hack += 1
      //console.log(this.changes_hack)
      let form_state = {}
      for (const varr in form_data) {
        form_state[varr] = this[varr]
      }
      if (this.changes_hack === majhack_number) {
        this.starting_values = JSON.stringify(form_state)
        if (!this.client_id) {
          this.unsaved_changes = false
          return null
        }
      }
      this.saved = JSON.stringify(form_state) === this.starting_values
      this.unsaved_changes = !this.saved
    },
    save() {
      this.$refs.form.validate()
      if (this.saving || this.saved || !this.name_address.operating_name) { return 0 }
      this.saving = true
      let payload = {id: this.client_id ? deURLify(this.client_id) : ''}
      for (const varr in form_data) {
        payload[varr] = this[varr]
      }
      return axios
        .post(process.env.VUE_APP_API_URL + '/secure/client_store', payload)
        .then(({ data }) => {
          console.log('server response:', data)
          this.$store.dispatch('fetch_clients')
          .then(() => {
            if (data.name_changed && data.saved && !this.navigate_dialog) {
              this.$router.push({name: 'Client Details', params: {client_id: URLify(this.name_address.operating_name)} })
              this.populate_customer_data()
              console.log('changes hacked @', this.changes_hack)
            }
          })
          if (data.saved) {
            console.log("Record Saved Successfully")
            this.changes_hack = majhack_number - 1
            this.track_changes()
          } else {
            console.log("there was an error ")
          }
          this.saving = false
        })
        .catch(err =>{
          this.saving = false
          if (err.response && err.response.status === 401) {
            console.log("You were not logged in.  Try again?")
            this.$store.dispatch('login_dialog', true)
            return null
          }
          const error_msg = err.response ? err.response.statusText : err
          console.log("Contacting the server failed with the following error: " + error_msg)
        })
    },
    save_changes(yes) {
      if (yes) { this.save() }
      this.unsaved_changes = false
      this.$router.push({name: 'Client List'})
    },
    populate_customer_data() {
      var customer_data = this.$store.state.clientlist.clients.filter(v => v.operating_name === this.operating_name)[0]
      this.customer_data = {...this.customer_data, ...customer_data}
      let starting_values = JSON.stringify(customer_data)
      
      this.$store.dispatch('fetch_clients').then(() => {
        var customer_data = this.$store.state.clientlist.clients.filter(v => v.operating_name === this.operating_name)[0]
        if (!customer_data) {
          this.saved = false
          console.log('client by the name '+ this.operating_name +' does not exist.  Create it?')
          return 0
        }
        const new_values = JSON.stringify(customer_data)
        if (starting_values != new_values) {
          this.changes_hack = 0
          this.customer_data = {...this.customer_data, ...customer_data}
        }
      })
    }
  },
  created() {
    if (this.client_id){
      this.populate_customer_data()
    } else {
      this.changes_hack -= 1
      this.$store.dispatch('fetch_clients')
    }
  },
  beforeRouteLeave(to, from, next) {
  if (this.unsaved_changes) {
    this.navigate_dialog = true
  } else {
    next(true)
  }
}
}
</script>
