<template>
  <v-card>
    <v-card-text>
      <ServiceProviderDetails 
        title="Accountant" 
        icon="mdi-circle-multiple" 
        :values="values.accountant || {}" 
        @change="accountant = $event; change()"
      />
      <v-divider class="mt-2"></v-divider>
      <ServiceProviderDetails 
        title="Lawyer"
        icon="mdi-scale-balance"
        :values="values.lawyer || {}" 
        @change="lawyer = $event; change()"
      />
      <v-divider class="mt-2"></v-divider>
      <ServiceProviderDetails
        title="Bank Manager" 
        icon="mdi-account-cash" 
        firm="Bank" 
        firm_icon="mdi-bank" 
        :values="values.banker || {}" 
        @change="banker = $event; change()"
      />
    </v-card-text>
  </v-card>
</template>

<script>
let form_variables = {
  accountant: {},
  lawyer: {},
  banker: {}
}
import ServiceProviderDetails from './serviceProviderDetails.vue'
export default {
  components: { ServiceProviderDetails },
  props: { values: Object },
  data: () => ({
    ...form_variables
  }),
  watch: {
    values() {
      this.update_form_variables()
    }
  },
  methods: {
    change() {
      let payload = {}
      for (const form_var in form_variables) {
        payload[form_var] = this[form_var]
      }
      this.$emit('change', payload)
    },
    update_form_variables() {
      const values = this.values
      for (const item in form_variables) {
        if (this[item] && values[item] && (this[item] != values[item]) && (typeof form_variables[item] != 'object') ) {
          console.log('nameAddress.vue:', item, 'conflict!', this[item], 'to be overwritten by', values[item])
        } else if (values[item]) {
          this[item] = values[item]
        }
      }
      this.change()
    }
  },
  created() {
    this.update_form_variables()
  }
}
</script>