import Vue from 'vue'
import App from './App.vue'
import router from './router.js'
import store from './state.js'
import vuetify from "./vuetify.js"

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
