<template>
  <v-card>
    <v-card-text>
      <C0mb0b0x
        v-model="operating_name"
        :items="businesses"
        label="Business Name"
        prepend_icon="mdi-office-building"
        :rules="[v => !!v || 'Business name cannot be left blank', v => !forbidden_business_names.includes(v) || 'Business name already exists - must choose unique name before saving']"
        @change="change"
      />
      
      <v-text-field
        v-model="legal_name"
        label="Legal Name"
        prepend-icon="mdi-scale-balance"
        @change="change"
      ></v-text-field>

      <v-card
        flat
        class="d-flex flex-wrap align-content-center flex-grow-1"
      >
        <v-card
          flat
          class="flex-grow-1 flex-shrink-1"
        >
          <v-text-field
            v-model="business_phone"
            label="Business Phone"
            prepend-icon="mdi-phone"
            @change="change"
          ></v-text-field>
        </v-card>
        <v-card
          flat
          class="flex-grow-1 flex-shrink-1"
        >
          <v-text-field
            v-model="business_fax"
            label="Business Fax"
            prepend-icon="mdi-fax"
            @change="change"
          ></v-text-field>
        </v-card>
      </v-card>

      <v-divider class="my-2"></v-divider>
      <v-subheader>Business Address</v-subheader>

      <AddressBlock :values="values.physical_address || {}" @change="physical_address = $event; change()" />

    </v-card-text>
  </v-card>
</template>

<script>
let form_variables = {
  operating_name: '',
  legal_name: '',
  business_phone: '',
  business_fax: '',
  physical_address: {}
}
import AddressBlock from './addressBlock.vue'
import C0mb0b0x from './c0mb0b0x.vue'
export default {
  components: { AddressBlock, C0mb0b0x },
  props: {values: Object},
  data: () =>({
    ...form_variables,
  }),
  computed: {
    businesses() {
      return this.$store.state.clientlist.clients.map(v => v.operating_name)
    },
    forbidden_business_names() {
      return this.$store.state.clientlist.clients.map(v => v.operating_name).filter(v => v != this.values.operating_name)
    }
  },
  watch: {
    values() {
      this.update_form_variables()
    },
  },
  methods: {
    test() {
      console.log(this.operating_name.trim())
    },
    change() {
      let payload = {}
      for (const form_var in form_variables) {
        payload[form_var] = this[form_var]
      }
      this.$emit('change', payload)
    },
    update_form_variables() {
      const values = this.values
      for (const item in form_variables) {
        if (this[item] && values[item] && (this[item] != values[item]) && (typeof form_variables[item] != 'object') ) {
          console.log('nameAddress.vue:', item, 'conflict!', this[item], 'to be overwritten by', values[item])
        } else if (values[item]) {
          this[item] = values[item]
        }
      }
      this.change()
    }
  },
  created() {
    this.update_form_variables()
  }
}
</script>