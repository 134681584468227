<template>
  <v-row dense>
    <v-col cols="12" sm="6" v-bind:class="{ 'my-n2': $vuetify.breakpoint.smAndDown }">
      <v-text-field
        v-model="name"
        :label="'Contact Name' + (order ? ' ('+order+')' : '')"
        prepend-icon="mdi-account"
        @change="change"
      ></v-text-field>
    </v-col><v-col cols="12" sm="6" v-bind:class="{ 'my-n2': $vuetify.breakpoint.smAndDown }">
      <v-text-field
        v-model="dob"
        label="Date of Birth"
        prepend-icon="mdi-calendar"
        placeholder="YYYY-MM-DD"
        @change="change"
      ></v-text-field>
    </v-col>

    <v-col cols="12" sm="3" class="my-n2">
      <v-text-field
        v-model="sin"
        label="Social Insurance Number"
        prepend-icon="mdi-numeric"
        @change="change"
      ></v-text-field>
    </v-col><v-col cols="12" sm="4" class="my-n2">
      <v-text-field
        v-model="phone"
        label="Cell Phone"
        prepend-icon="mdi-phone"
        @change="change"
      ></v-text-field>
    </v-col><v-col cols="12" sm="5" class="my-n2">
      <v-text-field
        v-model="email"
        label="Email"
        prepend-icon="mdi-email"
        @change="change"
      ></v-text-field>
    </v-col>
  </v-row>
</template>

<script>
let form_variables = {
  name: '',
  dob: '',
  sin: '',
  phone: '',
  email: '',
}
export default {
  props: {
    values: Object,
    order: String,
  },
  data: () => ({
    ...form_variables
  }), 
  watch: {
    values() {
      this.update_form_variables()
    }
  },
  methods: {
    change() {
      let payload = {}
      for (const form_var in form_variables) {
        payload[form_var] = this[form_var]
      }
      this.$emit('change', payload)
    },
    update_form_variables() {
      const values = this.values
      for (const item in form_variables) {
        if (this[item] && values[item] && (this[item] != values[item]) && (typeof form_variables[item] != 'object') ) {
          console.log('nameAddress.vue:', item, 'conflict!', this[item], 'to be overwritten by', values[item])
        } else if (values[item]) {
          this[item] = values[item]
        }
      }
      this.change()
    }
  },
  created() {
    this.update_form_variables()
  }
}
</script>