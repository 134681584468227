<template>
  <v-container fluid>
    <v-card class="mb-16">
      <v-card-title>
        Client List
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
        <!--v-btn @click="test">test</v-btn-->
      </v-card-title>
      <v-card-subtitle>
        <FilterSelector @change="active_filters = $event" />
        {{filter_title}}
      </v-card-subtitle>
      <v-data-table
        ref="dt"
        :headers="headers"
        :items="filtered_clients"
        :search="search"
        multi-sort
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        class="elevation-1"
        hide-default-footer
        :items-per-page="1000"
      >
        <template v-slot:item.business_address="{ item }">
          <span>{{addressify(item.physical_address)}}</span>
        </template>
        <template v-slot:item.condensed_address="{ item }">
          <span>{{addressify({...item.physical_address, condensed: true})}}</span>
        </template>
        <template v-slot:item.delete="{ item }">
          <v-btn 
            color="warning"
            icon
            @click="prepped_client = item.operating_name; delete_dialog=true"
          >
            <v-icon small>mdi-delete</v-icon>
          </v-btn>
        </template>
        <template v-slot:header.edit>
          <ColumnSelector :columns="headers" :hack='header_hack' @change="visible_columns = $event" />
        </template>
        <template v-slot:item.edit="{ item }">
          <v-btn 
            icon
            :to="{name: 'Client Details', params: {client_id: URLify(item.operating_name)} }"
            exact
          >
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
    
    <SimpleDialog
      :model="delete_dialog"
      title="Erase Client?"
      :body="'This will delete all client information for ' + prepped_client +'.  Are You Sure?'"
      yes="Keep"
      no="Delete Client"
      @response="delete_client(!$event)"
      @dialog="delete_dialog = $event"
    />

    <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          color="pink"
          class="white--text"
          fab
          :to="{name: 'Client Details' }"
          style="position:fixed;bottom:0;right:0;margin: 16px 16px 16px 16px;"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <span>add new client</span>
    </v-tooltip>

  </v-container>
</template>

<script>
/*
2do:
- fix VS code linting errors: https://vuejs.github.io/vetur/guide/linting-error.html#error-checking
- fix URLify failing to tell the difference between spaces & tabs
*/

const columns = [
  {text: "Business Name", value: "operating_name", align: "start" },
  {text: "Legal Name", value: "legal_name", align: "start" },
  {text: "Accountability Manager", value: "account_manager", align: "start" },
  {text: "Business Phone", value: "business_phone", align: " d-none" },
  {text: "Business Fax", value: "business_fax", align: " d-none" },
  {text: "Business Address", value: "business_address", align: " d-none" },
  {text: "Condensed Address", value: "condensed_address", align: " d-none" },
  {text: "Street", value: "physical_address.address1", align: " d-none" },
  {text: "Unit #", value: "physical_address.address1", align: " d-none" },
  {text: "Postal/Zip Code", value: "physical_address.zip", align: " d-none" },
  {text: "City", value: "physical_address.city", align: " d-none" },
  {text: "Province/State", value: "physical_address.province", align: " d-none" },
  {text: "Country", value: "physical_address.country", align: " d-none" },
  {text: "Primary Contact", value: "primary_contact.name", align: "start" },
  {text: "Primary's Number", value: "primary_contact.phone", align: " d-none" },
  {text: "Primary's Email", value: "primary_contact.email", align: " d-none" },
  {text: "Primary's Birthday", value: "primary_contact.dob", align: " d-none" },
  {text: "Primary's S.I.N.", value: "primary_contact.sin", align: " d-none" },
  {text: "Alternate Contact", value: "secondary_contact.name", align: " d-none" },
  {text: "Alternate's Number", value: "secondary_contact.phone", align: " d-none" },
  {text: "Alternate's Email", value: "secondary_contact.email", align: " d-none" },
  {text: "Alternate's Birthday", value: "secondary_contact.dob", align: " d-none" },
  {text: "Alternate's S.I.N.", value: "secondary_contact.sin", align: " d-none" },
  {text: "Initial Referral", value: "how", align: " d-none" },
  {text: "Legal Entity", value: "legal_entity", align: " d-none" },
  {text: "Year End", value: "year_end", align: " d-none" },
  {text: "Business #", value: "business_number", align: "start" },
  {text: "EHT #", value: "eht_number", align: " d-none" },
  {text: "WSIB #", value: "wsib_number", align: " d-none" },
  {text: "WSIB expiry", value: "wsib_expiry", align: " d-none" },
  {text: "HST #", value: "hst_number", align: " d-none" },
  {text: "HST code", value: "hst_access", align: " d-none" },
  {text: "Remittance Frequency", value: "remit_freq", align: " d-none" },
  {text: "Source Deductions", value: "source_deductions", align: " d-none" },
  {text: "RC59", value: "signed_rc59", align: " d-none" },
  {text: "CC Auth", value: "signed_cc", align: " d-none" },
  {text: "WSIB ✓", value: "signed_wsib", align: " d-none" },
  {text: "ROE ✓", value: "signed_roe", align: " d-none" },
  {text: "Accountant", value: "accountant.name", align: " d-none" },
  {text: "Accounting Firm", value: "accountant.firm_name", align: " d-none" },
  {text: "Lawyer", value: "lawyer.name", align: " d-none" },
  {text: "Law Firm", value: "lawyer.firm_name", align: " d-none" },
  {text: "Banker", value: "banker.name", align: " d-none" },
  {text: "Bank", value: "banker.firm_name", align: " d-none" },
  {text: "Date Registered", value: "date_joined", align: " d-none" },
  {text: "Date Inactive", value: "date_left", align: " d-none" },
  {text: 'Delete', value: 'delete', sortable: false, align:  " d-none" },
  {text: "Notes", value: "notes", align: " d-none" },
  {text: 'Edit', value: 'edit', sortable: false, align: 'start' },
]
const delete_index = columns.length - 3
//function addybreviate(input) {
//  if (input === 'United States of America') {
//    return 'USA'
//  } else if (input === 'Ontario') {
//    return 'ON'
//  }
//}

import ColumnSelector from './columnSelector.vue'
import FilterSelector from './filterSelector.vue'
import SimpleDialog from '@/ClientDetails/simpledialog.vue'

import { mapState } from 'vuex'
import axios from 'axios'
export default {
  name: 'ClientList',
  components: { ColumnSelector, FilterSelector, SimpleDialog },
  props: ['column_codes'],
  data: () => ({
    search: '',
    headers: columns,
    edit: true,
    visible_columns: [],
    sortBy: [],
    sortDesc: [],
    active_filters: [],
    delete_dialog: false,
    prepped_client: '',
    header_hack: false,
  }),
  computed: {
    ...mapState({ clients: state => state.clientlist.clients }),
    filtered_clients() {
      var filtered_clients = [...this.$store.state.clientlist.clients]
      for (const filter_item of this.active_filters) {
        filtered_clients = filtered_clients.filter(filter_item.filter)
      }
      return filtered_clients
    },
    filter_title() {
      return this.active_filters.length ? 'Filters: ' + (this.active_filters.map(v => v.text).join(', ')) : ''
    }
  },
  watch: {
    visible_columns(newvals, oldvals) {
      const removed = oldvals.filter(v=>!newvals.includes(v))[0]
      const added = newvals.filter(v=>!oldvals.includes(v))[0]
      if (removed >= 0) {
        this.headers[removed].align = ' d-none'
      } else if (added >= 0) {
        this.headers[added].align = 'start'
      }
      this.URLupdate()
    },
    sortBy() { this.URLupdate() },
    sortDesc() { this.URLupdate() },
    delete_dialog(dialog) {
      if (dialog) {return 0}
      this.headers[delete_index].align = ' d-none'
      this.header_hack = ! this.header_hack
    }
  },
  methods: {
    URLify(url) {
      return encodeURIComponent(url.replace(/[\s_]/g, function($1) { return $1 === '_' ? ' ' : '_' }))
    },
    URLupdate() {
      // Takes the instructions from the page & updates the URL accordingly
      // for testing uncomment following line
      //console.log('in', this.sortBy, this.sortDesc, this.visible_columns)
      var sort_columns = []
      for (const col_name of this.sortBy) {
        const col_index = columns.findIndex((v) => v.value === col_name)
        sort_columns.push(col_index)
      }
      var url = []
      for (const col of [...this.visible_columns].sort((a, b) => a - b)) {
        url.push(col.toString())
        const sort_index = sort_columns.indexOf(col)
        if (sort_index === -1){
          url.push('U')
        } else {
          url.push(this.sortDesc[sort_index] ? 'D' : 'A')
          url.push(String.fromCharCode(97 + sort_index))
        }
      }
      url = url.join('')

      const new_path = url === '0U1U2U6U19U40U' ? '/' : '/?col=' + url
      if (new_path != this.$route.fullPath) { this.$router.push(new_path) }
      
      // for testing uncomment the following line
      //this.URLapply(url)
    },
    URLapply(url) {
      // Takes the instructions from the URL & applies them to the page
      if (!url) {return 0}
      var visible_columns = [0]
      var ordered_sort_columns = {}
      var ordered_sortedBy = {}
      for (const char of url) {
        if (char >= '0' && char <= '9'){
          visible_columns[visible_columns.length - 1] = (10*visible_columns[visible_columns.length - 1]) + parseInt(char)
        } else if (['A', 'D', 'U'].includes(char)) {
          visible_columns.push(0)
          var sort_direction = char
        } else {
          const last_col = visible_columns.slice(-2)[0]
          var sort_order = char.charCodeAt(0) - 97
          ordered_sort_columns[sort_order] = last_col
          ordered_sortedBy[sort_order] = sort_direction === 'D'
        }
      }
      visible_columns.pop()

      const number_of_sort_columns = Object.keys(ordered_sort_columns).length
      var sortBy = []
      var sortDesc = []
      var osc_index
      for (osc_index = 0; osc_index < number_of_sort_columns; osc_index++) {
        var col = ordered_sort_columns[osc_index]
        sortBy.push(columns[col].value)
        sortDesc.push(ordered_sortedBy[osc_index])
      }

      // for testing uncomment the following line
      //console.log('out', sortBy, sortDesc, visible_columns)
      // for testing comment all following lines
      var header_id
      for (header_id = 0; header_id < this.headers.length; header_id++) {
        this.headers[header_id].align = visible_columns.includes(header_id) ? 'start' :  ' d-none'
      }
      this.sortBy = sortBy
      this.sortDesc = sortDesc
    },
    delete_client(yes){
      this.delete_dialog = false
      if (!yes) { return 0 }
      axios
        .post(process.env.VUE_APP_API_URL + '/secure/client_delete', {id:this.prepped_client})
        .then(() => {
          console.log('client removed')
          this.$store.dispatch('fetch_clients')
          // I bet there a bug here where filtered clients might not be displayed after a delete... maybe not though
        })
    },
    addressify({address1, address2, city, province, country, zip, condensed}) {
      var addy = []
      if (address1) {addy.push(address1)}
      if (address2) { addy.push(' (' + address2 + ')') }
      if (condensed) {
        if (city && city.toLowerCase().includes('windsor')) {
          if(zip){addy.push('  ' + zip)}
          return addy.join('')
        }
        if(city){ addy.push(' ' + city) }
        if (province && province.toLowerCase().includes('ontario')) {
          if(zip){addy.push('  ' + zip)}
          return addy.join('')
        }
        if(province){ addy.push(', ' + province) }
        if(zip){
          addy.push('  ' + zip)
          return addy.join('')
        }
        if (country === 'Canada') {
          return addy.join('')
        }
      } else {
        if(city){ addy.push(' ' + city)}
        if(province){ addy.push(', ' + province)}
        if(zip){ addy.push('  ' + zip)}
      }
      if(country){ addy.push(' ' + country)}
      return addy.join('')
    },
    test() {
      console.log('test')
    }
  },
  created() {
    this.$store.dispatch('fetch_clients')
    this.URLapply(this.column_codes)
  }
}
</script>
