<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn 
        icon
        v-bind="attrs"
        v-on="on"
      >
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-icon small v-bind="attrs" v-on="on">mdi-filter-variant</v-icon>
          </template>
          <span>Filter Clients</span>
        </v-tooltip>
      </v-btn>
    </template>
    <v-list dense class="overflow-y-auto" max-height="400">
      <v-list-item-group
        v-model="active_filters"
        color="primary"
        multiple
      >
        <v-list-item v-for="(item, i) in filters" :key="i">
          <template v-slot:default="{ active, }">
            <v-list-item-action>
              <v-checkbox
                :input-value="active"
                color="primary"
              ></v-checkbox>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>{{item.title}}</v-list-item-title>
            </v-list-item-content>
          </template>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>

const filters = [
  {title: 'Hide Inactive Clients', text: 'active', filter: (v) => !v.date_left},
  {title: 'Hide Clients Without Business Numbers', text: 'with business number', filter: (v) => v.business_number},
  {
    title: 'Hide Clients Without Pending Signatures', 
    text: 'signatures required', 
    filter: (v) => [v.signed_rc59, v.signed_cc, v.signed_wsib, v.signed_roe].includes('TBD')
  },
]

export default {
  data: () => ({
    filters: filters,
    active_filters: [],
  }),
  watch: {
    active_filters(active_filters) {
      this.$emit('change', filters.filter((v,i) => active_filters.includes(i)))
    }
  }
}
</script>