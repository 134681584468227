import Vue from 'vue'
import Vuex from 'vuex'

import login from '@/login/login.state.js'
import clientlist from '@/ClientList/clientlist.state.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    login,
    clientlist,
  }
})
