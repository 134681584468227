<template>
  <v-card>
    <v-card-text>

      <v-row dense>
        <v-col cols="12" sm="6" v-bind:class="{ 'my-n2': $vuetify.breakpoint.smAndDown }">
          <v-text-field
            v-model="legal_entity"
            label="Legal Entity"
            prepend-icon="mdi-scale-balance"
            @change="change"
          ></v-text-field>
        </v-col><v-col cols="12" sm="6" v-bind:class="{ 'my-n2': $vuetify.breakpoint.smAndDown }">
          <v-text-field
            v-model="year_end"
            label="Fiscal Year End"
            placeholder="MM-DD"
            prepend-icon="mdi-calendar"
            @change="change"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" class="my-n2">
          <v-text-field
            v-model="business_number"
            label="Business Number"
            prepend-icon="mdi-numeric"
            @change="change"
          ></v-text-field>
        </v-col><v-col cols="12" sm="6" class="my-n2">
          <v-text-field
            v-model="eht_number"
            label="Employer Health Tax Number"
            prepend-icon="mdi-hospital"
            @change="change"
          ></v-text-field>
        </v-col>
        
        <v-col cols="12" sm="6" class="my-n2">
          <v-text-field
            v-model="wsib_number"
            label="WSIB Number"
            prepend-icon="mdi-sign-caution"
            @change="change"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" class="my-n2">
          <v-text-field
            v-model="wsib_expiry"
            label="WSIB Expiry"
            prepend-icon="mdi-calendar"
            @change="change"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" class="my-n2">
          <v-text-field
            v-model="hst_number"
            label="HST Number"
            prepend-icon="mdi-leaf-maple"
            @change="change"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" class="my-n2">
          <v-text-field
            v-model="hst_access"
            label="HST Access Code"
            prepend-icon="mdi-lock"
            @change="change"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" class="my-n2">
          <v-text-field
            v-model="remit_freq"
            label="Remittance Frequency"
            prepend-icon="mdi-dont-know"
            @change="change"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" class="my-n2">
          <v-text-field
            v-model="source_deductions"
            label="Source Deductions"
            prepend-icon="mdi-who-knows"
            @change="change"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-divider class="mt-4"></v-divider>
      <v-subheader>Authorization Checklist</v-subheader>

      <v-row dense>
        <v-col cols="12" sm="6" v-bind:class="{ 'my-n2': $vuetify.breakpoint.smAndDown }">
          <SuperCheckbox
            v-model="signed_rc59"
            label="Signed RC59"
            :states="4"
            @change="change"
          />
        </v-col><v-col cols="12" sm="6" v-bind:class="{ 'my-n2': $vuetify.breakpoint.smAndDown }">
          <SuperCheckbox
            v-model="signed_cc"
            label="Credit Card Authorization"
            :states="4"
            @change="change"
          />
        </v-col>

        <v-col cols="12" sm="6" class="my-n2">
          <SuperCheckbox
            v-model="signed_wsib"
            label="WSIB Authorization"
            :states="4"
            @change="change"
          />
        </v-col><v-col cols="12" sm="6" class="my-n2">
          <SuperCheckbox
            v-model="signed_roe"
            label="R.O.E. Authorization"
            :states="4"
            @change="change"
          />
        </v-col>
      </v-row>

      <v-divider class="my-4"></v-divider>

      <C0mb0b0x
        v-model="account_manager"
        :items="['AM','DS','GL','GW','JS','LGB','LMB']"
        label="Accountability Account Manager"
        prepend-icon="mdi-account"
        @change="change"
      />

      <v-row dense>
        <v-col cols="12" sm="6" v-bind:class="{ 'my-n2': $vuetify.breakpoint.smAndDown }">
          <v-text-field
            v-model="date_joined"
            label="Registration Date"
            hint="Date at which client joined Accountability"
            placeholder="YYYY-MM-DD"
            prepend-icon="mdi-calendar-plus"
            @change="change"
          ></v-text-field>
        </v-col><v-col cols="12" sm="6" v-bind:class="{ 'my-n2': $vuetify.breakpoint.smAndDown }">
          <v-text-field
            v-model="date_left"
            label="Inactive Date"
            hint="Date at which client ceased being active"
            placeholder="YYYY-MM-DD"
            prepend-icon="mdi-calendar-minus"
            @change="change"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-textarea
        v-model="notes"
        label="Notes"
        rows="8"
        @change="change"
      ></v-textarea>

    </v-card-text>
  </v-card>
</template>

<script>
let form_variables = {
  legal_entity: '',
  year_end: '',
  business_number: '',
  eht_number: '',
  wsib_number: '',
  wsib_expiry: '',
  hst_number: '',
  hst_access: '',
  remit_freq: '',
  source_deductions: '',
  signed_rc59: false,
  signed_cc: false,
  signed_wsib: false,
  signed_roe: false,
  account_manager: null,
  date_joined: '',
  date_left: '',
  notes: '',
}

import SuperCheckbox from './superCheckbox.vue'
import C0mb0b0x from './c0mb0b0x.vue'
export default {
  components: {SuperCheckbox, C0mb0b0x},
  props: {values: Object},
  data: () =>({
    ...form_variables,
  }),
  watch: {
    values() {
      this.update_form_variables()
    }
  },
  methods: {
    change() {
      let payload = {}
      for (const form_var in form_variables) {
        payload[form_var] = this[form_var]
      }
      this.$emit('change', payload)
    },
    update_form_variables() {
      const values = this.values
      for (const item in form_variables) {
        if (this[item] && values[item] && (this[item] != values[item]) && (typeof form_variables[item] != 'object') ) {
          console.log('nameAddress.vue:', item, 'conflict!', this[item], 'to be overwritten by', values[item])
        } else if (values[item]) {
          this[item] = values[item]
        }
      }
      this.change()
    }
  },
  created() {
    this.update_form_variables()
  }
}
</script>