<template>
  <v-app>
    <v-main>
      <router-view />
      <Login />
    </v-main>
  </v-app>
</template>

<script>
import { mapState } from 'vuex';
import Login from "./login/login.vue"
export default {
  components: { Login },
  computed: {
    ...mapState({ user: state => state.login.user }),
  },
  watch: {
    user(user) {
      if (user) { this.$store.dispatch('fetch_clients') }
    }
  }
}
</script>