<template>
  <div>
    <v-autocomplete
      v-model="country"
      :items="countries"
      label="Country"
      @change="change('country')"
      clearable
    >
      <template v-slot:prepend>
        <country-flag :country="Country.flag" />
      </template>
    </v-autocomplete>

    <v-card flat class="d-flex flex-wrap align-content-center flex-grow-1" >
      <v-card flat class="flex-grow-1 flex-shrink-1" >
        <v-text-field
          v-model="address1"
          label="Street Address"
          prepend-icon="mdi-office-building-marker"
          @change="change"
        ></v-text-field>
      </v-card>
      <v-card flat class="flex-grow-1 flex-shrink-1">
        <v-text-field
          v-model="address2"
          label="Apt / Suite / Unit"
          prepend-icon="mdi-domain"
          @change="change"
        ></v-text-field>
      </v-card>
    </v-card>

    <v-row no-gutters>
      <v-col>
        <v-text-field
          v-model="city"
          prepend-icon="mdi-map-marker"
          label="City"
          @change="change"
        ></v-text-field>
      </v-col><v-col>
        <v-autocomplete
          v-model="province"
          :items="Country.divisions"
          prepend-icon="mdi-earth"
          :label="Country.division_label"
          @change="change('province')"
          clearable
        ></v-autocomplete>
      </v-col><v-col>
        <v-text-field
          v-model="zip"
          prepend-icon="mdi-postage-stamp"
          :label="Country.shipping_code_label"
          @change="change"
        ></v-text-field>
      </v-col>
    </v-row>
  </div>
</template>

<script>
const provinces_of_Canada = ['Alberta', 'British Columbia', 'Manitoba', 'New Brunswick', 'Newfoundland and Labrador', 'Nova Scotia', 'Ontario', 'Prince Edward Island', 'Quebec', 'Saskatchewan', 'Northwest Territories', 'Nunavut', 'Yukon']
const states_of_America = ["Alabama", "Alaska", "American Samoa", "Arizona", "Arkansas", "California", "Colorado", "Connecticut", "Delaware", "District Of Columbia", "Federated States Of Micronesia", "Florida", "Georgia", "Guam", "Hawaii", "Idaho", "Illinois", "Indiana", "Iowa", "Kansas", "Kentucky", "Louisiana", "Maine", "Marshall Islands", "Maryland", "Massachusetts", "Michigan", "Minnesota", "Mississippi", "Missouri", "Montana", "Nebraska", "Nevada", "New Hampshire", "New Jersey", "New Mexico", "New York", "North Carolina", "North Dakota", "Northern Mariana Islands", "Ohio", "Oklahoma", "Oregon", "Palau", "Pennsylvania", "Puerto Rico", "Rhode Island", "South Carolina", "South Dakota", "Tennessee", "Texas", "Utah", "Vermont", "Virgin Islands", "Virginia", "Washington", "West Virginia", "Wisconsin", "Wyoming"]
const Countries = {
  'Canada': {
    division_label: 'Province',
    shipping_code_label: "Postal Code",
    divisions: provinces_of_Canada,
    provinces: provinces_of_Canada,
    flag: 'ca'
  },
  'United States of America': {
    division_label: 'State',
    shipping_code_label: "Zip Code",
    divisions: states_of_America,
    states: states_of_America,
    flag: 'us'
  },
  null: {
    division_label: 'Province',
    shipping_code_label: "Postal Code",
    divisions: [],
    provinces: [],
    flag: 'aq'
  }
}
const Earth = {
  /*
  2do:
  - post to github, npm, & PyPi
    - turn into a more complete mapping of Countries & Provinces
    - write an appeal for help
    - can generate a different mapping for UN-accepted, Chinese-accepted, shipping-relevant
  */
  division_label: 'Country',
  divisions: Countries,
  countries: Object.keys(Countries).filter(v=>v!='null'),
  ...Countries
}

let form_variables = {
  country: 'Canada',
  address1: '',
  address2: '',
  city: 'Windsor',
  province: 'Ontario',
  zip: '',
}
const default_province = { 'Canada': 'Ontario', 'United States of America': null, null: null }
import CountryFlag from 'vue-country-flag'
export default {
  components: { CountryFlag },
  props: { values: Object },
  data: () =>({
    ...form_variables
  }),
  computed: {
    Country() { return Earth[this.country] },
    countries() { return Earth.countries },
  },
  watch: {
    values: function () {
      this.update_form_variables()
    }
  },
  methods: {
    change(changed) {
      if (changed === 'province') { default_province[this.country] = this.province }
      if (changed === 'country')  { this.province = default_province[this.country] }

      const valuers = {}
      for (const varr in form_variables) {
        valuers[varr] = this[varr]
      }
      this.$emit('change', valuers)
    },
    update_form_variables() {
      const values = this.values
      for (const item in form_variables) {
        if (['Windsor', 'Ontario', 'Canada'].includes(this[item]) && values[item]) {
          this[item] = values[item]
        } else if (this[item] && values[item] && (this[item] != values[item]) && (typeof form_variables[item] != 'object') ) {
          console.log('nameAddress.vue:', item, 'conflict!', this[item], 'to be overwritten by', values[item])
        } else if (values[item]) {
          this[item] = values[item]
        }
      }
      this.change()
    }
  },
  created() {
    this.update_form_variables()
  }
}
</script>