import Vue from 'vue'
import VueRouter from 'vue-router'
import ClientList from '@/ClientList/ClientList.vue'
import ClientDetails from '@/ClientDetails/ClientDetails.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Client List',
    component: ClientList,
    props: route => ({ column_codes: route.query.col }),
    meta: {title: 'AccountAbilityApp - Client List'}
  },{
    path: '/client/:client_id?',
    name: 'Client Details',
    component: ClientDetails,
    props: true,
    meta: {title: 'AccountAbilityApp - Client Details'}
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.afterEach((to) => {
  Vue.nextTick( () => {
    document.title = to.meta.title ? to.meta.title : 'AccountAbilityApp';
  });
})

export default router
