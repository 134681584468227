import axios from 'axios'
axios.defaults.withCredentials = true

export default ({
  state: {
    clients: [],
  },
  mutations: {
    UPDATE_CLIENTS (state, clients) {
      state.clients = clients
    },
  },
  actions: {
    fetch_clients({ commit }) {
      return axios
      .get(process.env.VUE_APP_API_URL + '/secure/clientlist')
      .then(response => {
        commit('UPDATE_CLIENTS', response.data )
      })
      .catch(error => {
        this.clients = []
        if (! error.response) {
          console.log('clients fetch failure...')
          console.log(error)
        } else if (error.response.status === 401) {
          //this.state.login.user = null
          this.dispatch('login_dialog', true)
        } else {
          console.log('clients fetch error:')
          console.log(error.response)
        }
      })
    }
  }
});
