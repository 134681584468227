<template>
  <v-combobox
    v-model="vmodel_local"
    :items="items"
    :label="label"
    :prepend-icon="prepend_icon"
    @update:search-input="comboboxhackfunc"
    @blur="blur"
    :rules="rules"
  ></v-combobox>
</template>

<script>
export default {
  props: {
    vmodel: String,
    items: Array,
    label: String,
    prepend_icon: String,
    rules: Array,
  },
  model: {
    prop: 'vmodel'
  },
  data: () =>({
    comboboxhackvar: null,
    vmodel_local: ''
  }),
  watch: {
    vmodel() { this.vmodel_local = this.vmodel }
  },
  methods: {
    comboboxhackfunc(currentValue) {
      this.comboboxhackvar = currentValue
      if (this.vmodel === this.comboboxhackvar) { return null }
      this.$emit('input', this.comboboxhackvar)
      this.$emit('change', this.comboboxhackvar)
    },
    blur(){
      this.vmodel_local = this.vmodel_local.trim()
      if (this.vmodel === this.vmodel_local) { return null }
      this.$emit('input', this.comboboxhackvar)
      this.$emit('change', this.comboboxhackvar)
    }
  },
  created() {
    this.vmodel_local = this.vmodel
  }
}
</script>