<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn 
        icon
        v-bind="attrs"
        v-on="on"
      >
        <v-tooltip top >
          <template v-slot:activator="{ on, attrs }">
            <v-icon small v-bind="attrs" v-on="on">mdi-view-column</v-icon>
          </template>
          <span>show/hide columns</span>
        </v-tooltip>
      </v-btn>
    </template>
    <v-list dense class="overflow-y-auto" max-height="400">
      <v-list-item-group
        v-model="visible_columns"
        color="primary"
        multiple
      >
        <v-list-item v-for="(item, i) in columns" :key="i">
          <template v-slot:default="{ active, }">
            <v-list-item-action>
              <v-checkbox
                :input-value="active"
                color="primary"
              ></v-checkbox>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>{{item.text}}</v-list-item-title>
            </v-list-item-content>
          </template>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: {columns: Array, hack: Boolean},
  data: () => ({
    visible_columns: [],
  }),
  watch: {
    columns (columns) {
      console.log('hopefully the reactivity here will be fixed in Vue 3')
      this.visible_columns = columns.map((v,i) => v.align === 'start' ? i : -1).filter(v => v >=0)
    },
    visible_columns(cols) {
      this.$emit('change', cols)
    },
    hack () {
      this.visible_columns = this.columns.map((v,i) => v.align === 'start' ? i : -1).filter(v => v >=0)}
  },
  created() {
    this.visible_columns = this.columns.map((v,i) => v.align === 'start' ? i : -1).filter(v => v >=0)
  }
}
</script>